import React, { useState, useRef, useEffect } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';

const SimpleCalendar = ({ events, darkMode }) => {
    const [currentDate, setCurrentDate] = useState(new Date());
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
    const calendarRef = useRef(null);
    const tooltipRef = useRef(null);
  
    const daysInMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0).getDate();
    const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 0).getDay();
  
    const days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  
    const prevMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1));
    };
  
    const nextMonth = () => {
      setCurrentDate(new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1));
    };
  
    const showTooltip = (event, dayEvents) => {
      const calendarRect = calendarRef.current.getBoundingClientRect();
      const cellRect = event.target.getBoundingClientRect();
      
      let x = cellRect.left - calendarRect.left + cellRect.width / 2;
      let y = cellRect.top - calendarRect.top + cellRect.height;
  
      const tooltipWidth = 200;
      const tooltipHeight = 100;
      
      if (x + tooltipWidth > calendarRect.width) {
        x = calendarRect.width - tooltipWidth;
      }
      if (y + tooltipHeight > calendarRect.height) {
        y = cellRect.top - calendarRect.top - tooltipHeight;
      }
  
      setTooltipPosition({ x, y });
      setSelectedEvent(dayEvents);
    };
  
    const hideTooltip = () => {
      setSelectedEvent(null);
    };
  
    useEffect(() => {
      const handleClickOutside = (event) => {
        if (tooltipRef.current && !tooltipRef.current.contains(event.target)) {
          hideTooltip();
        }
      };
  
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  
    const getEventStyle = (event) => {
      const style = {
        backgroundColor: event.color || '#3490dc',
        color: '#ffffff',
        padding: '2px 4px',
        marginBottom: '2px',
        borderRadius: '2px',
        fontSize: '0.7rem',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        display: 'flex',
        alignItems: 'center',
        border: darkMode ? '1px solid rgba(255, 255, 255, 0.2)' : 'none'
      };
      
      if (event.isAllDay) {
        style.width = '100%';
      }
    
      return style;
    };

    const getCellClassName = (day) => {
      const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
      const today = new Date();
      const isToday = date.toDateString() === today.toDateString();
  
      let className = `border p-1 h-20 md:h-28 relative overflow-hidden`;
  
      if (darkMode) {
        className += ` bg-gray-700`; // Slightly lighter than the card background
        className += ` border-gray-900`;
      } else {
        className += ` bg-gray-200`; // Slightly darker than the card background
        className += ` border-gray-300`;
      }
  
      if (isToday) {
        className += ` ${darkMode ? 'bg-blue-700' : 'bg-blue-300'}`;
      }
  
      return className;
    };
  
    return (
      <div ref={calendarRef} className={`relative ${darkMode ? 'text-white' : 'text-gray-800'}`}>
        <div className="flex justify-between items-center mb-4">
          <button onClick={prevMonth} className={`${darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-800'}`}>
            <ChevronLeft size={20} />
          </button>
          <h2 className="text-lg font-semibold">{currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}</h2>
          <button onClick={nextMonth} className={`${darkMode ? 'text-gray-300 hover:text-white' : 'text-gray-600 hover:text-gray-800'}`}>
            <ChevronRight size={20} />
          </button>
        </div>
        <div className="grid grid-cols-7 gap-1">
          {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map(day => (
            <div key={day} className="text-center font-bold text-xs md:text-sm">{day}</div>
          ))}
          {Array(firstDayOfMonth).fill(null).map((_, index) => (
            <div key={`empty-${index}`} />
          ))}
          {days.map(day => {
            const date = new Date(currentDate.getFullYear(), currentDate.getMonth(), day);
            const dayEvents = events
            .filter(event => {
              const eventStart = new Date(event.startDate);
              const eventEnd = new Date(event.endDate);
              eventStart.setHours(0, 0, 0, 0);
              eventEnd.setHours(23, 59, 59, 999);
              const cellDate = new Date(date);
              cellDate.setHours(0, 0, 0, 0);
              return cellDate >= eventStart && cellDate <= eventEnd;
            })
            .sort((a, b) => {
              if (a.isAllDay && !b.isAllDay) return -1;
              if (!a.isAllDay && b.isAllDay) return 1;
              if (a.isAllDay && b.isAllDay) return 0;
              return a.startTime.localeCompare(b.startTime);
            });
            return (
              <div key={day} className={getCellClassName(day)}>
                <div className="text-right text-xs">{day}</div>
                <div className="absolute top-5 left-0 right-0 bottom-0 overflow-y-auto">
                  {dayEvents.map(event => (
                    <div
                      key={event.id}
                      style={getEventStyle(event)}
                      onClick={(e) => showTooltip(e, [event])}
                      className="cursor-pointer text-xs"
                    >
                      {!event.isAllDay && (
                        <span className="font-bold mr-1">{event.startTime}</span>
                      )}
                      {event.title}
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
        {selectedEvent && (
          <div 
            ref={tooltipRef}
            className={`absolute ${darkMode ? 'bg-gray-700 text-white' : 'bg-white text-gray-800'} border shadow-lg p-2 rounded z-10`}
            style={{ 
              top: tooltipPosition.y, 
              left: tooltipPosition.x,
              maxWidth: '200px',
              transform: 'translate(-50%, 0)'
            }}
          >
            {selectedEvent.map(event => (
              <div key={event.id} className="mb-2">
                <strong>{event.title}</strong>
                <br />
                {event.isAllDay ? (
                  <>
                    Start: {new Date(event.startDate).toLocaleDateString()}
                    <br />
                    End: {new Date(event.endDate).toLocaleDateString()}
                  </>
                ) : (
                  <>
                    Date: {new Date(event.startDate).toLocaleDateString()}
                    <br />
                    Time: {event.startTime} - {event.endTime}
                  </>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

export default SimpleCalendar;