import React, { useEffect, useRef } from 'react';
import { Home, MicVocal, Users, CalendarClock, Car, FileText, Settings, LogOut, Sun, Moon, X, BarChart2 } from 'lucide-react';

const Sidebar = ({ isOpen, toggleSidebar, darkMode, toggleDarkMode }) => {
    const sidebarRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target) && isOpen) {
                toggleSidebar();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [isOpen, toggleSidebar]);

    const menuItems = [
      { icon: Home, label: 'Dashboard', link: '#' },
      { icon: MicVocal, label: 'Events', link: '#' },
      { icon: Users, label: 'Users', link: '#' },
      { icon: CalendarClock, label: 'Shifts', link: '#' },
      { icon: Car, label: 'Vehicles', link: '#' },
      { icon: FileText, label: 'Invoices', link: '#' },
      { icon: BarChart2, label: 'Reports', link: '#' },
      { icon: FileText, label: 'Documents', link: '#' },
      { icon: Settings, label: 'Settings', link: '#' },
    ];
  
    return (
      <div 
        ref={sidebarRef}
        className={`fixed inset-y-0 left-0 z-30 w-64 ${darkMode ? 'bg-gray-800 text-white border-gray-800' : 'bg-gray-100'} shadow-xl transform transition-transform duration-300 ease-in-out ${isOpen ? 'translate-x-0' : '-translate-x-full'} md:translate-x-0 flex flex-col`}
      >
        <div className={`flex items-center justify-between p-2 ${darkMode ? 'border-gray-700' : 'border-gray-300'} border-b`}>
          <h2 className="text-lg font-semibold ml-3">Admin Panel</h2>
          <button onClick={toggleSidebar} className="md:hidden">
            <X size={20} />
          </button>
        </div>
        <nav className="flex-grow overflow-y-auto">
          <ul className="py-2">
            {menuItems.map((item, index) => (
              <li key={index}>
                <a href={item.link} className={`flex items-center px-4 py-2 ${darkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-600 hover:bg-gray-200'}`}>
                  <item.icon className="mr-3" size={18} />
                  <span>{item.label}</span>
                </a>
              </li>
            ))}
          </ul>
        </nav>
        <div className="p-2 border-t border-gray-300 dark:border-gray-700">
          <button 
            onClick={toggleDarkMode} 
            className={`flex items-center w-full px-4 py-2 ${darkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-600 hover:bg-gray-200'}`}
          >
            {darkMode ? <Sun className="mr-3" size={18} /> : <Moon className="mr-3" size={18} />}
            <span>{darkMode ? 'Light Mode' : 'Dark Mode'}</span>
          </button>
        </div>
        <div className="p-2">
          <button className={`flex items-center w-full px-4 py-2 ${darkMode ? 'text-red-400 hover:bg-gray-700' : 'text-red-500 hover:bg-gray-200'}`}>
            <LogOut className="mr-3" size={18} />
            <span>Logout</span>
          </button>
        </div>
      </div>
    );
};

export default Sidebar;