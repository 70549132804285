import React from 'react';
import { UserPlus, CalendarClock, PieChart, Ticket, Megaphone } from 'lucide-react';

const QuickActions = () => {
  const buttonClasses = "w-full py-2 px-4 rounded transition-colors duration-200 flex items-center justify-center text-white";
  const primaryButtonClasses = `${buttonClasses} bg-blue-600 hover:bg-blue-700`;
  const secondaryButtonClasses = `${buttonClasses} bg-gray-600 hover:bg-gray-700`;
  
  return (
    <div className="space-y-4">
      <div className="grid grid-cols-2 gap-4">
        <button className={primaryButtonClasses}>
          <UserPlus className="mr-2" size={20} />
          Add User
        </button>
        <button className={primaryButtonClasses}>
          <CalendarClock className="mr-2" size={20} />
          Create Shift
        </button>
        <button className={primaryButtonClasses}>
          <PieChart className="mr-2" size={20} />
          View Reports
        </button>
        <button className={primaryButtonClasses}>
          <Ticket className="mr-2" size={20} />
          Manage Events
        </button>
      </div>
      <button className={`${buttonClasses} bg-red-600 hover:bg-red-700`}>
      <Megaphone className="mr-2" size={24} />
        Send Notification
      </button>
    </div>
  );
};

export default QuickActions;