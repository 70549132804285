import React, { useState, useEffect, useRef } from 'react';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/card';
import Sidebar from './components/Sidebar';
import QuickActions from './components/QuickActions';
import SimpleCalendar from './components/Calendar';
import EventModal from './components/EventModal'; 
import { Bell, Activity, Users, AlertTriangle, Plus, Menu } from 'lucide-react';

const predefinedColors = ['#3490dc', '#9333ea', '#16a34a', '#e3342f', '#661cd5', '#db2777', '#ea580c'];

const App = () => {
  const [showProfileMenu, setShowProfileMenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const profileMenuRef = useRef(null);
  const notificationsRef = useRef(null);

  // Updated list of events for September 2024
  const [events, setEvents] = useState([
    { id: 1, title: 'Team Meeting', startDate: '2024-09-05', endDate: '2024-09-05', startTime: '10:00', endTime: '11:00', color: predefinedColors[0] },
    { id: 2, title: '24/7 Meeting', startDate: '2024-09-08', endDate: '2024-09-08', startTime: '14:00', endTime: '15:30', color: predefinedColors[1] },
    { id: 3, title: 'Lunch - Steve', startDate: '2024-09-10', endDate: '2024-09-10', startTime: '12:30', endTime: '13:30', color: predefinedColors[2] },
    { id: 4, title: 'Alice Cooper - Swansea Arena', startDate: '2024-09-12', endDate: '2024-09-12', isAllDay: true, color: predefinedColors[3] },
    { id: 5, title: 'Liam Gallagher - Cardiff Arena', startDate: '2024-09-18', endDate: '2024-09-18', isAllDay: true, color: predefinedColors[4] },
    { id: 6, title: 'Arctic Monkeys - Swansea Stadium', startDate: '2024-09-22', endDate: '2024-09-26', isAllDay: true, color: predefinedColors[5] },
    { id: 7, title: 'Pink - Cardiff Stadium', startDate: '2024-09-21', endDate: '2024-09-28', isAllDay: true, color: predefinedColors[6] },
  ]);

  const [showEventModal, setShowEventModal] = useState(false);
  const [newEvent, setNewEvent] = useState({
    title: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    color: '',
    isSingleDay: true,
    isAllDay: true,
  });

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(() => {
    const savedMode = localStorage.getItem('darkMode');
    return savedMode ? JSON.parse(savedMode) : false;
  });

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
        setShowProfileMenu(false);
      }
      if (notificationsRef.current && !notificationsRef.current.contains(event.target)) {
        setShowNotifications(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleDarkMode = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    localStorage.setItem('darkMode', JSON.stringify(newMode));
  };

  const handleCreateEvent = () => {
    if (newEvent.title && newEvent.startDate) {
      const eventToAdd = {
        ...newEvent,
        id: events.length + 1,
        isMultiDay: !newEvent.isSingleDay,
        endDate: newEvent.isSingleDay ? newEvent.startDate : newEvent.endDate,
        color: newEvent.color || predefinedColors[events.length % predefinedColors.length]
      };
      setEvents([...events, eventToAdd]);
      setShowEventModal(false);
      setNewEvent({
        title: '',
        startDate: '',
        endDate: '',
        startTime: '',
        endTime: '',
        color: '',
        isSingleDay: true,
        isAllDay: true
      });
    }
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
    setShowProfileMenu(false); // Close profile menu when opening notifications
  };

  const toggleProfileMenu = () => {
    setShowProfileMenu(!showProfileMenu);
    setShowNotifications(false); // Close notifications when opening profile menu
  };

  return (
    <div className={`flex h-screen overflow-hidden ${darkMode ? 'bg-gray-900 text-white' : 'bg-white'}`}>
      <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      
      <div className="flex-1 flex flex-col overflow-hidden md:ml-64">
        <header className={`${darkMode ? 'bg-gray-700' : 'bg-gray-150'} shadow-sm z-10`}>
          <div className={`max-w-full py-4 px-2 sm:px-11 lg:px-12 flex justify-between items-center ${darkMode ? 'text-white' : 'text-gray-900'}`}>
            <button onClick={toggleSidebar} className={`${darkMode ? 'text-gray-300 hover:text-gray-100' : 'text-gray-500 hover:text-gray-600'} md:hidden`}>
              <Menu size={24} />
            </button>
            <div className="flex items-center">
              <img 
                src={darkMode ? "/deliver-crew-cutout-logo-dark.png" : "/deliver-crew-cutout-logo-light.png"} 
                alt="Deliver Crew Logo" 
                className="h-8 w-auto mr-6 lg:mr-6"
              />
              <h1 className="text-2xl md:text-3xl font-bold">Dashboard</h1>
            </div>
            <div className="flex items-center">
              <div className="relative" ref={notificationsRef}>
                <button 
                  className="mr-4 relative"
                  onClick={toggleNotifications}
                >
                  <Bell size={24} />
                  <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
                    2
                  </span>
                </button>
                {showNotifications && (
                  <div className={`absolute right-0 mt-2 w-64 ${
                    darkMode 
                      ? 'bg-gray-900 shadow-[0_0_10px_rgba(0,0,0,0.5)]' 
                      : 'bg-white shadow-xl'
                  } rounded-md overflow-hidden z-10`}>
                    <div className={`px-4 py-2 ${darkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                      <p className={`text-sm font-bold ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>Notifications</p>
                    </div>
                    <div className={`divide-y ${darkMode ? 'divide-gray-700' : 'divide-gray-200'}`}>
                      <div className={`px-4 py-3 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                        <div className="flex items-center">
                          <AlertTriangle className="h-5 w-5 text-red-500 mr-2" />
                          <p className="text-sm font-medium">Accident Reported</p>
                        </div>
                        <p className="text-xs mt-1">High Priority Alert</p>
                      </div>
                      <div className={`px-4 py-3 ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                        <p className="text-sm">Jeff Goldblum has not started his shift</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="relative" ref={profileMenuRef}>
                <button onClick={toggleProfileMenu} className="flex items-center focus:outline-none">
                  <img src="/abbie.jpg" alt="Admin" className="h-8 w-8 rounded-full" />
                </button>
                {showProfileMenu && (
                  <div className={`absolute right-0 mt-2 w-48 ${
                    darkMode 
                      ? 'bg-gray-900 shadow-[0_0_10px_rgba(0,0,0,0.5)]' 
                      : 'bg-white shadow-xl'
                  } rounded-md overflow-hidden z-10`}>
                    <div className={`px-4 py-3 ${darkMode ? 'bg-gray-700' : 'bg-gray-100'}`}>
                      <p className={`text-sm font-bold ${darkMode ? 'text-gray-300' : 'text-gray-700'}`}>Abbie Courtney</p>
                    </div>
                    <a href="#" className={`block px-4 py-2 text-sm ${darkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100'}`}>Profile</a>
                    <a href="#" className={`block px-4 py-2 text-sm ${darkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100'}`}>Settings</a>
                    <a href="#" className={`block px-4 py-2 text-sm ${darkMode ? 'text-gray-300 hover:bg-gray-700' : 'text-gray-700 hover:bg-gray-100'}`}>Logout</a>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>

        <main className={`flex-1 overflow-y-auto ${darkMode ? 'bg-gray-900' : 'bg-gray-200'} p-4 md:p-6 space-y-6`}>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            <Card className={`lg:col-span-2 row-span-2 ${darkMode ? 'bg-gray-800 text-white border-gray-800' : 'bg-gray-50'}`}>
              <CardHeader>
                <CardTitle className="flex justify-between items-center">
                  <span>Calendar</span>
                  <button 
                    onClick={() => setShowEventModal(true)}
                    className="bg-blue-600 text-white p-2 rounded-full hover:bg-blue-700 transition-colors duration-200"
                  >
                    <Plus size={20} />
                  </button>
                </CardTitle>
              </CardHeader>
              <CardContent>
                <SimpleCalendar events={events} darkMode={darkMode} />
              </CardContent>
            </Card>

            <div className="flex flex-col space-y-4">
              <Card className={`${darkMode ? 'bg-gray-800 text-white border-gray-800' : 'bg-gray-50'}`}>
                <CardHeader>
                  <CardTitle>Quick Actions</CardTitle>
                </CardHeader>
                <CardContent>
                  <QuickActions darkMode={darkMode} />
                </CardContent>
              </Card>
              <Card className={`${darkMode ? 'bg-gray-800 text-white border-gray-800' : 'bg-gray-50'}`}>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Active Shifts</CardTitle>
          <Activity className={`h-4 w-4 ${darkMode ? 'text-gray-400' : 'text-muted-foreground'}`} />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">12</div>
          <p className={`text-xs ${darkMode ? 'text-gray-400' : 'text-muted-foreground'}`}>+2 from yesterday</p>
        </CardContent>
      </Card>

      <Card className={`${darkMode ? 'bg-gray-800 text-white border-gray-800' : 'bg-gray-50'}`}>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Total Users</CardTitle>
          <Users className={`h-4 w-4 ${darkMode ? 'text-gray-400' : 'text-muted-foreground'}`} />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">1,234</div>
          <p className={`text-xs ${darkMode ? 'text-gray-400' : 'text-muted-foreground'}`}>+7 new this week</p>
        </CardContent>
      </Card>

      <Card className={`${darkMode ? 'bg-gray-800 text-white border-gray-800' : 'bg-gray-50'}`}>
        <CardHeader className="flex flex-row items-center justify-between space-y-0 pb-2">
          <CardTitle className="text-sm font-medium">Alerts</CardTitle>
          <AlertTriangle className={`h-4 w-4 ${darkMode ? 'text-gray-400' : 'text-muted-foreground'}`} />
        </CardHeader>
        <CardContent>
          <div className="text-2xl font-bold">2</div>
          <p className={`text-xs ${darkMode ? 'text-gray-400' : 'text-muted-foreground'}`}>1 high priority</p>
        </CardContent>
      </Card>
    </div>

    
    <Card className={`${darkMode ? 'bg-gray-800 text-white border-gray-800' : 'bg-gray-50'}`}>
      <CardHeader>
        <CardTitle>Recent Activities</CardTitle>
      </CardHeader>
      <CardContent>
        <ul className="space-y-2">
          <li>John Doe started a shift</li>
          <li>Jane Smith completed a task</li>
          <li>New user registered: Mike Johnson</li>
        </ul>
      </CardContent>
    </Card>
            
          </div>
        </main>
      </div>

      {showEventModal && (
        <EventModal
          newEvent={newEvent}
          setNewEvent={setNewEvent}
          handleCreateEvent={handleCreateEvent}
          setShowEventModal={setShowEventModal}
          darkMode={darkMode}
        />
      )}
    </div>
  );
};

export default App;