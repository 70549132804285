import React from 'react';

const EventModal = ({ newEvent, setNewEvent, handleCreateEvent, setShowEventModal, darkMode }) => {
  return (
    <div className={`fixed inset-0 ${darkMode ? 'bg-gray-900' : 'bg-gray-300'} bg-opacity-80 flex items-center justify-center`}>
      <div className={`${darkMode ? 'bg-gray-800 text-gray-300 border-gray-600' : 'bg-white text-gray-900'} p-6 rounded-md shadow-lg max-w-md w-full`}>
        <h2 className="text-2xl font-bold mb-4">Create New Event</h2>
        <input
          type="text"
          value={newEvent.title}
          onChange={(e) => setNewEvent({ ...newEvent, title: e.target.value })}
          placeholder="Event Title"
          className={`w-full p-3 mb-4 border rounded ${darkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-900 border-gray-300'}`}
        />
        
        {/* Adjusting the checkboxes and label colors based on the theme */}
        <div className="flex items-center mb-4">
          <input
            type="checkbox"
            checked={newEvent.isSingleDay}
            onChange={(e) => setNewEvent({ 
              ...newEvent, 
              isSingleDay: e.target.checked, 
              endDate: e.target.checked ? newEvent.startDate : '' 
            })}
            className="mr-2"
          />
          <label className="mr-4">Single Day Event</label>
          <input
            type="checkbox"
            checked={newEvent.isAllDay}
            onChange={(e) => setNewEvent({ 
              ...newEvent, 
              isAllDay: e.target.checked 
            })}
            className="mr-2"
          />
          <label>All Day</label>
        </div>

        <input
          type="date"
          value={newEvent.startDate}
          onChange={(e) => setNewEvent({ 
            ...newEvent, 
            startDate: e.target.value, 
            endDate: newEvent.isSingleDay ? e.target.value : newEvent.endDate 
          })}
          className={`w-full p-3 mb-4 border rounded ${darkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-900 border-gray-300'}`}
        />

        {!newEvent.isSingleDay && (
          <input
            type="date"
            value={newEvent.endDate}
            onChange={(e) => setNewEvent({ 
              ...newEvent, 
              endDate: e.target.value 
            })}
            className={`w-full p-3 mb-4 border rounded ${darkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-900 border-gray-300'}`}
          />
        )}

        {!newEvent.isAllDay && (
          <div className="flex mb-4">
            <input
              type="time"
              value={newEvent.startTime}
              onChange={(e) => setNewEvent({ 
                ...newEvent, 
                startTime: e.target.value 
              })}
              className={`w-1/2 p-3 mr-2 border rounded ${darkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-900 border-gray-300'}`}
            />
            <input
              type="time"
              value={newEvent.endTime}
              onChange={(e) => setNewEvent({ 
                ...newEvent, 
                endTime: e.target.value 
              })}
              className={`w-1/2 p-3 border rounded ${darkMode ? 'bg-gray-700 text-white border-gray-600' : 'bg-white text-gray-900 border-gray-300'}`}
            />
          </div>
        )}

        <div className="flex justify-end">
          <button 
            onClick={() => setShowEventModal(false)} 
            className={`mr-2 p-3 rounded ${darkMode ? 'bg-gray-700 text-white hover:bg-gray-600' : 'bg-gray-200 text-gray-800 hover:bg-gray-300'}`}
          >
            Cancel
          </button>
          <button 
            onClick={handleCreateEvent} 
            className={`p-3 rounded ${darkMode ? 'bg-blue-600 text-white hover:bg-blue-700' : 'bg-blue-600 text-white hover:bg-blue-700'}`}
          >
            Create
          </button>
        </div>
      </div>
    </div>
  );
};

export default EventModal;
